@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Figtree", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

/* css by deepak pal */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.lineheightcustom {
  line-height: 80px !important;
}

.grianetcolor {
  background: #0052d4;
  background: -webkit-linear-gradient(to right, #6fb1fc, #4364f7, #0052d4);
  background: linear-gradient(to right, #6fb1fc, #4364f7, #0052d4);
}

.centerd-div {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.pt_o {
  padding-top: 0px !important;
}
.pb_o {
  padding-bottom: 0px !important;
}

.img_twos {
  background-image: url("../public/assets/img/front/twoimgbg.png");
  background-repeat: no-repeat;
  background-size: 55%;
  position: relative;
  background-position-y: bottom;
  background-position-x: right;
}

.bg_bag {
  /* background: url("../public/assets/img/front/bg_cover.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.gradient {
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(180deg, rgba(2, 11, 39, 0) 0%, #020b27 112.23%);
}

h2.background {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #69696d;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 16px 0px 16px;

  &:before {
    border-top: 1px solid #e3e5ed;
    content: "";
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
  }

  span {
    border-radius: 50px;
    background: white;
    padding: 0 5px;
  }
}

@media (min-width: 1330px) and (max-width: 1740px) {
  /* .list-decimal li {
		font-size: 18px !important;
	} */

  .rs-end-text {
    font-size: 38px !important;
  }
}

@media (max-width: 767px) {
  .img_twos {
    background-size: 100%;
  }
}

/* @media (min-width: 1500px) {
	.ifrmae_img {
		width: 405px;
	}
} */
/* @media (max-width: 992px) {
	iframe.ifrmae_img {
		width: 100%;
	}
} */

/* @media (min-width: 1024px) and (max-width: 1499px) {
	.ifrmae_img {
		width: 260px;
	}
} */

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  cursor: pointer;
}

.select_reacts .css-13cymwt-control {
  border-color: #fff !important;
  box-shadow: none !important;
  border: 0px !important;
  background: transparent !important;
  width: 100% !important;
}

.select_reacts .css-t3ipsp-control:hover {
  border-color: transparent !important;
  box-shadow: none !important;
  border: 0px !important;
  background: transparent !important;
  width: 100% !important;
}

.select_reacts .css-t3ipsp-control {
  border-color: transparent !important;
  box-shadow: none !important;
  border: 0px !important;
  background: transparent !important;
  width: 100% !important;
}

.select_reacts .css-13cymwt-control {
  width: 100%;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  height: 2.75rem;
}

.customwidth33 {
  width: 30.33%;
  border-right: 1px solid #8c8a8a70;
}

/*Start Slick Slider */
#imageSlider {
  bottom: 16px;
}
#imageSlider li {
  margin: 0;
  width: 12px;
  height: 12px;
}
#imageSlider li button:before {
  color: #d9d9d996;
  opacity: 1;
  font-size: 5px;
}
#imageSlider li.slick-active button:before {
  color: #1744d2;
  opacity: 1;
}
.campervan .slick-arrow.slick-prev.cbtn {
  left: unset !important;
}
.campervan .slick-arrow.slick-disabled.cbtn {
  background-color: #e7e5e5 !important;
  opacity: 0.3;
  /* border: 1px solid #2b2b2b80; */
  color: #2b2b2b !important;
  cursor: default;
  /* border:0.6px solid #2B2B2B80;	 */
}
.campervan .slick-prev.cbtn:hover,
.campervan .slick-prev.cbtn:focus,
.campervan .slick-next.cbtn:hover,
.campervan .slick-next.cbtn:focus {
  color: #fff;
  outline: none;
  /* border: 1px solid #2b2b2b80; */
  background: #1744d2;
}

/* End Slick Slider */
.customwidth33 {
  width: 30.33%;
  border-right: 1px solid #8c8a8a70;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #9f9f9f;
  border-radius: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #d1d1d1;
}

.img-white img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(5deg) brightness(102%) contrast(104%);
}

.select_reacts .css-1jqq78o-placeholder {
  font-size: 13px;
}
#profileEditNumber .react-tel-input .selected-flag {
  position: absolute !important;
  left: 14px !important;
  padding: 0 !important;
  width: 100% !important;
}

.time::-webkit-calendar-picker-indicator {
  filter: brightness(0) saturate(100%) invert(65%) sepia(3%) saturate(0%)
    hue-rotate(182deg) brightness(86%) contrast(88%);
}

input[type="checkbox"]:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: currentColor;
  background-position: 50% 80%;
  background-repeat: no-repeat;
  background-size: 90% 90%;
  border-color: transparent;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

input[type="radio"].custom-radio {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type="radio"].custom-radio:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: currentColor;
  background-position: 50% 80%;
  background-repeat: no-repeat;
  background-size: 90% 90%;
  border-color: transparent;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}
/* .css-13cymwt-control {
	border-radius:50px !important;
	border-color: #2b2b2b !important;
	white-space:nowrap;
	cursor:pointer !important;
	height:40px;
} */

.blogSelect .react__control {
  color: #69696d;
}

/* .css-t3ipsp-control{
	border-radius:50px !important;
	white-space:nowrap;
	outline: none;
	border-color:#2b2b2b !important;
    box-shadow:none !important;
	cursor:pointer !important;
} */

.blogSelect .react__input-container,
.react__indicator {
  color: #0a0a0b !important;
}

.css-1jqq78o-placeholder {
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  color: #8d8d8d;
}

/* .locationSelect .css-13cymwt-control{
    background-color:white;
    border-radius: 50px !important;
    border: 1px solid #8D8D8D !important;
    box-sizing: border-box;
	cursor: pointer;
} */

.css-1u9des2-indicatorSeparator {
  display: none;
}

/* .css-13cymwt-control:hover {
	border-color: #2b2b2b !important;
} */

.mobile_input .country-list .search-box {
  width: -webkit-fill-available;
  margin-right: 15px;
}
.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.d-none {
  display: none !important;
}
.pagination > li {
  padding: 2px;
}
.pagination {
  position: relative;
}
.pagination > li:first-child {
  /* Styles for the first li */
  background-color: white;
  width: 120px;
  height: 40px;
  border: 1px solid #e3e5ed;
  border-radius: 8px;
  color: #69696d;
  position: absolute;
  left: 0px;
  cursor: pointer;
}

@media (max-width: 758px) {
  .pagination > li:first-child {
    width: 70px;
    height: 36px;
    left: 10px;
  }
  .pagination > li:first-child {
    width: 70px;
    height: 36px;
    left: 10px;
  }
}

/* .pagination > li:first-child:hover {
    background-color:#1744D2 ;
	border: none;
	color: white;
}
.pagination > li:first-child:hover::before {
    background-color:#1744D2 ;
	border: none;
	color: white;
} */
.pagination > .disabled:first-child::before {
  content: "Previous";
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #e8e8e8;
  font-weight: 600;
}
.pagination > li:first-child::before {
  content: "Previous";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  color: #69696d;
  font-weight: 600;
}
@media (max-width: 758px) {
  .pagination > li:first-child::before {
    right: 7px;
    top: 10px;
    font-size: 10px;
  }
}
.pagination li:nth-child(2).active ~ li:nth-child(1) {
  pointer-events: none;
  opacity: 0.5; /* You can also visually indicate it's disabled */
}

.pagination > li:last-child {
  /* Styles for the last li */
  background-color: white;
  width: 120px;
  height: 40px;
  border: 1px solid #e3e5ed;
  border-radius: 8px;
  color: #69696d;
  position: absolute;
  right: 0px;
  cursor: pointer;
}
@media (max-width: 758px) {
  .pagination > li:last-child {
    width: 70px;
    height: 36px;
    right: 10px;
  }
  .pagination > li:last-child::before {
    left: 15px !important;
    top: 10px !important;
    font-size: 10px !important;
  }
  .pagination > li a,
  .pagination > li.active a {
    padding: 4px !important;
    font-size: 12px !important;
    min-width: 28px !important;
    height: 28px !important;
  }
  .aboutmissionsection h2:nth-child(3),
  .aboutmissionsection h2:nth-child(5) {
    font-size: 16px !important;
  }
}
.pagination > .disabled:last-child::before {
  content: "Next";
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 10px;
  color: #e8e8e8;
  font-weight: 600;
}
.pagination > li:last-child::before {
  content: "Next";
  position: absolute;
  left: 30px;
  top: 10px;
  font-size: 14px;
  color: #69696d;
  font-weight: 600;
}

.pagination > li a {
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  min-width: 40px;
  height: 40px;
  text-align: center;
  color: #69696d;
  text-decoration: none;
  font-weight: 600;
}
.pagination > li.active a {
  background-color: #1744d2;
  border-color: #1744d2;
  color: #f6f7fb;
}
.pagination > li.disabled a {
  border-color: #e8e8e8;
  color: #e8e8e8;
}

.react-tel-input .flag-dropdown {
  border: none !important;
}
.react-tel-input .form-control {
  border-color: #787878 !important;
  border-radius: 12px !important;
}
#citySelect .react__control {
  border-radius: 12px !important;
  height: 40px !important;
  border-color: #e3e5ed !important;
}

.ItinerariesSelect .react__control {
  min-width: auto;
}
#insuranceSelect .react__control {
  border-radius: 6px;
}

#paymentselectcountry .react__control {
  height: 40px;
  border-radius: 12px;
  border-color: #e3e5ed !important;
  border-width: 1.4px;
}
.bookinglocationSelect .react__control {
  height: 40px;
  border-radius: 12px;
  /* border-color: #787878 !important; */
  border-width: 1.4px;
}
/* .bookinglocationSelect .react__control:focus{
	height: 50px;
	border-radius: 8px;
	border-color: #2849B2 !important;
	border-width: 1.4px;

}	 */
.react__clear-indicator:hover {
  color: red !important;
}
.css-14n3qf5-control:hover {
  border-color: #1744d2 !important;
}
.react__control:hover {
  border-color: #8d8d8d !important;
}
.flag-dropdown {
  background-color: transparent !important;
  padding-left: 4px !important;
}
.aboutmissionsection h2:nth-child(3) {
    /* Styles for the first <p> tag */
	margin: 16px 0 12px;
    color:#0A0A0B;
	font-size: 18px;
	font-weight: 600; 
	display: flex;
	gap: 12px;
}
/* for add the icon in about us page  */
.aboutmissionsection h2:nth-child(3)::before {
  content: "";
  background-color: #1744d2;
  background-image: url("/public/assets/img/front/crisis_alert.svg");
  /* no-repeat center center */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 100%;
  width: 36px !important;
  height: 36px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutmissionsection h2:nth-child(5) {
    /* Styles for the first heading tag in about us */
	margin:16px 0 12px;
    color:#0A0A0B;
	font-size: 18px;
	font-weight: 600; 
	display: flex;
	gap: 12px;
}
.aboutmissionsection h2:nth-child(5)::before {
  content: "";
  background-color: #1744d2;
  background-image: url("/public/assets/img/front/eye_tracking.svg");
  /* no-repeat center center */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 100%;
  width: 36px !important;
  height: 36px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookingdetailsborder .ant-card-body {
  border: 1px solid #e3e5ed;
  border-radius: 16px;
}
/* for selecting the days only  */
.tracking-wide.text-gray-500 {
  color: #344054 !important;
  font-weight: 500 !important;
  font-size: 16px;
  /* position: relative; */
  /* display: inline-block;
    overflow: hidden;
    width: 2ch;
	letter-spacing: 2px;
	white-space: nowrap; */
}

/* for only showing two alphabets */

/* .tracking-wide.text-gray-500::after{
	content: '';
    position: absolute;
    right: 0;
    background: white;
} */

.customcalenderstyling div > div > div > div > div:nth-child(1) > div {
  border: none;
  /* background-color: red;*/
}
.customcalenderstyling div > div > div > div > div:nth-child(3) > div {
  border: none;
  /* background-color: red; */
}

/* .customcalenderstyling div > div > div > div > div:nth-child(1) > div,  
.customcalenderstyling div > div > div > div > div:nth-child(3) > div {
	border: none;
} */
.customcalenderstyling div > div > div > div:nth-child(1) > div > div,
.customcalenderstyling div > div > div > div:nth-child(3) > div > div {
  border: none;
}
/* .customcalenderstyling div > div{
	border-radius: 20px;
	margin-top: 10px;
} */
/* complete div position  */
#details_calender .customcalenderstyling > div {
  right: 0 !important;
  left: auto !important;
}

#details_calender .customcalenderstyling > div > div:nth-child(1) {
  right: 18px !important;
  left: auto;
}
.customcalenderstyling > div {
  position: absolute;
  top: 39px;
  left: 0px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  /* border-radius: 20px; */
  background-color: transparent;
}
/* .customcalenderstyling > div > div{
	border-radius: 20px;
} */
.customcalenderstyling > div > div:nth-child(2) {
  border-radius: 20px;
}
/* for the adjusting the position of arrow  */
.customcalenderstyling > div > div:nth-child(1) {
  left: 10px;
}
/* for middle small line */
.customcalenderstyling > div > div > div > div > div:nth-child(2) {
  display: none;
}
/* for the dates which has been passes */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.line-through.items-center {
  color: #bebebe;
}
/* for removing the dates that are in previous month  */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.text-gray-400 {
  color: #bebebe;
  opacity: 0;
}
/* current date color  */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.text-blue-500 {
  color: #1744d2;
}
/* for removing the left selected border date  */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.rounded-l-full {
  border: none;
  border-radius: 0px;
  color: white !important;
  background-color: #1744d2 !important;
}
/* for removing the right selected border date  */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.rounded-r-full {
  border: none;
  border-radius: 0px;
  color: white !important;
  background-color: #1744d2 !important;
}
/* for the selected dates  */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.bg-blue-100 {
  border: none;
  border-radius: 0px;
  color: white !important;
  background-color: #1744d2;
  opacity: 0.5;
}
/* for the  coming dates  */
.customcalenderstyling
  div
  > div
  > div
  > div
  > div
  > div
  > div
  > button.items-center {
  font-size: 14px;
  color: #2b2b2b;
}
/* for icons colors */
.customcalenderstyling div > div > div > div > div > div {
  /* color: red; */
}
/* for the month name and year */
.tracking-wide.uppercase {
  color: #344054 !important;
  font-weight: 600 !important;
  font-size: 16px;
}

.customcalenderstyling * {
  z-index: 1000;
}
.customcalenderstyling > div {
  width: fit-content;
}
/* dirction of calender */
.customcalenderstyling > div > div > div > div {
  display: flex;
  flex-direction: column;
}
/* for adjusting the whole calender to avoid overflow */
@media (min-width: 1041px) {
  .customcalenderstyling > div > div > div > div {
    flex-direction: row;
  }
}
@media (min-width: 640px) {
  .dashed-border {
    border: 2px solid transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='4'%3E%3Cpath d='M0 2 H100' stroke='%232849B2' stroke-width='4' stroke-dasharray='0.75,0.75'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-size: 100% 1px;
  }
}

/* CSS for Shine Animation */
@keyframes shine {
  0% {
    left: -100%;
    width: 100%;
  }
  50% {
    left: calc(100% - 20px); /* Adjust as needed */
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

.shine-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.shine {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  transform: skewX(-20deg);
  animation: shine 2s infinite;
}

.zplus__menu {
  z-index: 999999 !important;
}

.w-full.bg-\[\#2849b21c\].p-4.lg\:px-\[55px\].lg\:py-6.mt-4.lg\:mt-6.rounded-\[14px\].show-order
  ol {
  list-style: auto;
}

.zplus__indicator {
  padding: 0 8px !important;
  color: #0a0a0b !important;
}
#imageSlider .slick-active {
  background-color: white;
  border-radius: 100%;
}
#lisitngDriveAge .react__control {
  min-width: min-content;
}
.slick-dots li button:before {
  content: "";
  top: -3px !important;
  left: -4px !important;
}
/* For validation text of inputs  */
.d-block {
  color: red !important;
  margin-top: 2px !important;
}

/* For custom radio btn */
.radio-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 12px;
}

.custom-radio {
  height: 24px; /* Outer circle size */
  width: 24px; /* Outer circle size */
  background-color: transparent;
  border: 1px solid #1744D2;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.radio-container input:checked + .custom-radio::after,
.radio-container input:checked + .custom-radio-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px; /* Inner circle size */
  height: 8px; /* Inner circle size */
  background-color: #1744D2;
  border-radius: 50%;
}

.custom-radio-btn {
  height: 20px; /* Outer circle size */
  width: 20px; /* Outer circle size */
  background-color: transparent;
  border: 1px solid #1744D2;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
